.page-content .wrapper {
    .post-toc {
        &>ul {
            overflow: auto;
        }
    }
}

.util-notify1 {
    position: absolute;
    z-index: 100;
    background: rgba(0,0,0,0.7);
    color: #FFF;
    padding: 5px 10px;
    font-size: 14px;
    pointer-events: none;
    opacity: 1;
    transition: .2s ease;
    
    &.hidden {
        opacity: 0;
        display: none;
    }
}