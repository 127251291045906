.post {
    .post-header {
        .post-title {
            font-size: 40px;
            font-weight: normal;
            @include media-query($on-palm) {
                font-size: 34px;
            }
        }
        .post-meta {
            color: #999;
            font-size: $small-font-size;
        }
        @include media-query($on-palm) {
            text-align: center;
            margin-bottom: 10px;
            padding: 10px;
        }
    }
    .post-content {
        color: #333;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #000;
        }
        h1,
        h2 {
            margin-bottom: 10px;
            margin-top: 30px;
        }
        h1,
        h2,
        h3 {
            border-bottom: 1px solid #CCC;
        }
        pre {
            @include media-query($on-palm) {
                margin: 0 -10px;
            }
        }
        .middle-image {
            display: block;
            margin-left: auto;
            margin-right: auto
        }
    }
    .post-comments {
        padding-top: 30px;
    }
    %note {
        color: white;
        padding: 20px 20px 24px 50px;
        position: relative;
        &:before {
            position: absolute;
            top: 15px;
            left: 15px;
            font-size: 32px;
            font-weight: 700;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
        }
    }
    .warning {
        @extend %note;
        background: linear-gradient(#663100, #7e4105);
        &:before {
            content: '!';
            color: #fc0;
        }
    }
    .info {
        @extend %note;
        background: linear-gradient(#022a66, #0f3172);
        &:before {
            content: '\24D8';
            color: rgb(122, 222, 240);
        }
    }
}