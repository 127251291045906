.home {
.page-heading {
	font-size:40px;
	font-weight: normal;
    @include media-query($on-palm) {
		margin: 10px;
		font-size: 34px;
    }
}
.rss-link {
	float:right;
    text-decoration: none;
    border:1px solid;
    color: #999;
    background: #fff;
    font-size: 0.7 * $base-font-size;
    padding: 1px 8px;
    
    &:hover, &:focus {
        color: #666;
    }
}
.post-list {
    margin: 0;
	padding: 20px 0px;
    list-style: none;
    > li {
        padding-bottom: 30px;
        border-bottom: 1px solid #CCC;
    	margin-bottom: 30px;
    }
    .post-title {
    	font-size: 28px;
    	font-weight: normal;
    	margin: 0;
    }
    .post-meta {
    	font-size: $small-font-size;
    	color: #666;
        margin-bottom: .5em;
    }
    .post-excerpt {
        &, * {
            font-size: $small-font-size;
            padding-top: 0;
            padding-bottom: 0;
	        color: #333;
        }
        code, pre, pre * {
            color: #666;
            background-color: #eee;
        }
        th {
            border-color: #ccc;
            background-color: #ccc;
        }
        a {
            color: $color-link-hover;
        }
        img {
            display: block;
            margin: 1em auto;
            max-height: 16em;
            opacity: .7;
            transition: opacity .3s;
            &:hover {
                opacity: 1;
            }
        }
    }
    @include media-query($on-palm) {
		padding: 10px;
    }
}

.pagination {
	text-align:center;
	text-decoration:none;
	color: #666;
	margin: 30px 0;
    .page_number {
    	margin:0 30px;
    }
    .previous,.next {
    	padding: 10px 20px;
    	white-space: nowrap;
    }
    a.previous,a.next {
    	color: #333;
    	border-radius: 20px;
    	border: 1px solid #CCC;
    }
    a.previous:hover,a.next:hover {
    	background: #fefefe;
    	color: #000;
    	text-decoration:none;
    }
}
}